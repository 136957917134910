.rbt-header .mainmenu-nav .mainmenu li.with-megamenu .rbt-megamenu.grid-item-3 {
    width: 900px;
    margin: 0 900px;
}

.logo a img {
    max-height: 100px;
    object-fit: cover;
}

.rbt-header .logo a img {
    max-height: 100px;
    object-fit: cover;
}

.rbt-category-update .update-category-dropdown .inner .dropdown-parent-wrapper .dropdown-parent-list .dropdown-child-wrapper {
    position: absolute;
    top: 0;
    left: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    background-color: var(--color-white);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease;
    min-width: 600px !important;
    border-left: 1px solid #e6e3f14f;
    border-radius: 0 0 10px 0;
    min-height: 100%;
}

.popup-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 500px;
    text-align: center;
}

.popup-dialog h4 {
    margin-bottom: 10px;
}

.popup-dialog p {
    margin-bottom: 20px;
}

.popup-dialog button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.popup-dialog button:hover {
    background-color: #0056b3;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}


.shake-animation {
    animation: shake 0.5s;
    animation-iteration-count: 1;
}

.clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.scroll-animation-wrapper::before, .scroll-animation-wrapper::after{
    background: none;
}

.scroll-animation-wrapper::before, .scroll-animation-wrapper::before{
    background: none;
}